import {
  Alert,
  Box,
  Button,
  Checkbox,
  Input,
  Modal,
  Multiselect,
  MultiselectProps,
  SpaceBetween,
  TextContent,
  Textarea,
} from '@cloudscape-design/components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tenant } from 'twirp/api'
import { useModalData } from './ModalContext'
import { TCCService } from 'services/api'
import { useGetObjectDefinitions } from 'hooks/useGetObjectDefinitions'

export const ExportTenantModalName = 'ExportTenantModal'

interface ExportTenantModalProps {
  tenant: Tenant
}

export const ExportTenantModal = (props: ExportTenantModalProps) => {
  const navigate = useNavigate()
  const [templateName, setTemplateName] = useState(props.tenant.name + ' template')
  const [templateDescription, setTemplateDescription] = useState('')
  const [includeObjectData, setIncludeObjectData] = useState(false)
  const { visible, disableModal } = useModalData(ExportTenantModalName)
  const [excludedObjectDefinitions, setExcludedObjectDefinitions] = useState<MultiselectProps.Option[]>([])
  const handleInitiateTenantExport = async () => {
    const templateData = await TCCService.InitiateTenantExport({
      tenantId: props.tenant.id,
      templateName: templateName,
      templateDescription: templateDescription,
      includeObjectData: includeObjectData,
      excludedObjectDefinitions: excludedObjectDefinitions.map((od) => od.value!),
    })
    navigate(`/templates/${templateData.templateId}`)
  }
  const { data: objectDefinitions, isLoading: isLoadingObjectDefinitions } = useGetObjectDefinitions(props.tenant.id)

  return (
    <Modal
      header="Export Tenant"
      visible={visible}
      onDismiss={disableModal}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleInitiateTenantExport} disabled={!templateName}>
              Export
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Box padding="s">
        <Box>
          <TextContent>
            <h5>Template Name</h5>
            <Input
              inputMode="text"
              value={templateName}
              onChange={({ detail }) => setTemplateName(detail.value)}
              invalid={!templateName}
            />
          </TextContent>
        </Box>
        <Box padding={{ top: 's' }}>
          <TextContent>
            <h5>Template Description</h5>
            <Textarea value={templateDescription} onChange={({ detail }) => setTemplateDescription(detail.value)} />
          </TextContent>
        </Box>
        <Box padding={{ top: 's' }}>
          <Checkbox
            checked={includeObjectData}
            onChange={({ detail }) => {
              setIncludeObjectData(detail.checked)
              setExcludedObjectDefinitions([])
            }}
          >
            Include Data
          </Checkbox>
        </Box>
        <Box padding={{ top: 's' }}>
          <TextContent>
            <h5>Excluded Object Definitions</h5>
            <Multiselect
              disabled={!includeObjectData}
              placeholder="Choose object definitions"
              loadingText="Loading object definitions"
              statusType={isLoadingObjectDefinitions ? 'loading' : 'finished'}
              selectedOptions={excludedObjectDefinitions}
              options={objectDefinitions?.map((od) => ({ label: od, value: od })) ?? []}
              onChange={({ detail }) =>
                setExcludedObjectDefinitions(detail.selectedOptions as MultiselectProps.Option[])
              }
            />
          </TextContent>
        </Box>
      </Box>
      <Alert type="info">
        Exporting the tenant will capture existing configurations and/or data to create a new template.
      </Alert>
    </Modal>
  )
}
