import { useQuery } from 'react-query'
import { axiosRPC } from 'services/api'
import { TendoCloudConfiguratorServiceClientJSON } from 'twirp/api.twirp-client'

export const useGetObjectDefinitions = (tenantId: string) => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  return useQuery({
    queryKey: ['objectDefinitions', tenantId],
    queryFn: async () => {
      const resp = await client.ListObjectDefinitions({ tenantId: tenantId })
      return resp.objectDefinitions.map((od) => od.name)
    },
  })
}
